
// Colours
.text-light-gray {
  color: $light-gray !important;
}

.text-medium-gray {
  color: $medium-gray !important;
}

.text-dark-gray {
  color: $dark-gray !important;
}

.text-alert {
  color : $alert-color !important;
}

// headings
.alpha-header {
  @extend h1;
}

.beta-header {
  @extend h2;
}

.gamma-header {
  @extend h3;
}

.delta-header {
  @extend h4;
}

.epsilon-header {
  @extend h5;
}

.zeta-header {
  @extend h6;
}

// weights
.font-weight-light {
  font-weight: 100;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-semibold {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
}