/*------------------------------------*\
    #HEADER
\*------------------------------------*/

body[data-intellisearch='active'] {
  .site-overlay {
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    -webkit-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -ms-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    background-color: rgba(10, 10, 10, 0.7);
    z-index: 4;
    //-webkit-filter: blur(5px);
    //filter: blur(5px);
  }
}
header {
  background-color: $white;
  float: left;
  width:100%;

  .header__logo {
    margin-top: 18px;
    text-align: left;
    margin-left: 35px;
    @include breakpoint (medium down) {
      text-align: center;
      margin: 1.25rem 0 0 0;
    }
    a {
      display: block;
      width:9.313rem;
      background:url('../img/apaceurope-logo.svg') no-repeat left top;
      background-size:9.313rem 71px;
      height: 71px;
      image-rendering: -webkit-optimize-contrast;
      @include breakpoint (medium down) {
        margin: 0 auto;
      }
      @include retina {
        background:url('../img/apaceurope-logo.svg') no-repeat center;
        background-size:9.313rem 71px;
      }
    }
  }


  .header__signin-apply {
    text-align: center;
    position: relative;
    float: left;
    width: 100%;
    display: inline-block;
    h5 {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 15px;
      letter-spacing: 1px;
      margin-top: 5px;
    }
  }

  .header__signin {
    position: relative;
    width: 100%;
    margin-top: 28px;
  }

  .header__apply {
    position: relative;
    width: 100%;
  }




  .header__mini {
    background: $color-secondary;
    position: relative;
    font-size: 0.875rem;
    font-weight: 600;
    border-bottom: 2px solid $color-tertiary;
    a {
      color: white;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
    }
    ul {
      &.menu {
        li a {
          &:hover {
            background: $color-hover;
            color: #02113d;
          }
        }
      }
      li {
        &.dropdown__link--brands {
          position: relative;
        }
      }
      &.menu {
        li {
          &.is-active {
            background: $site-brand-color-1;
            > a {
              color: $secondary-color;
            }
          }
          &.is-dropdown-submenu-parent {
            > a {
              &::after {
                border-color: $white transparent transparent;
              }
            }
            &.is-active {
              > a {
                &::after {
                  border-color: $secondary-color transparent transparent;
                }
              }
            }
          }
        }
      }
      &.is-dropdown-submenu {
        z-index: 4;
        background: $site-brand-color-1;
        li {
          a {
            color: #02113d;
          }
          &:hover {
            background: $secondary-color;
            a {
              color: #02113d;
            }
          }
        }
      }
      @include breakpoint (small only) {
        &.is-dropdown-submenu {
          position: absolute;
          background-color: $site-brand-color-1 !important;
          a {
            color: $secondary-color !important;
            font-size: 1rem !important;
          }
          li {
            &:hover {
              a {
                color: $white !important;
              }
            }
          }
        }
      }
    }
  }/* END header__mini */

  .header__top {
    width: 100%;
    top:0;
    bottom: 0;
    right: 0;
    left:0;
    background: #f1f1f1;
    z-index: 3;
    //border-bottom: 1px solid $light-gray;
    //transition: height 0.5s ease, max-height 0.5s ease, top 0.5s ease;
    //-webkit-transition: height 0.5s ease, max-height 0.5s ease, top 0.5s ease;
    //-moz-transition: height 0.5s ease, max-height 0.5s ease, top 0.5s ease;
    //-ms-transition: height 0.5s ease, max-height 0.5s ease, top 0.5s ease;
    height: auto;
    max-height: 124px;
    &--inactive {
      position: relative;
      max-height: none;
      padding-top: 13px;
      padding-bottom: 13px;
    }
    &--active {
      position: relative;
      .header__logo {
        margin-top: 10px !important;
        a {
          width: 8.313rem;
          background-size: 8.313rem 61px;
          height: 61px;
        }
      }
    }
    @include breakpoint (medium down) {
      text-align: center;
      position: relative;
      height: auto;
      border-bottom: 0px;
    }
    ul {
      list-style-type: none;
      margin: 1.5rem 0 0 0;
      float: right;
      @include breakpoint (medium down) {
        float: none;
      }
      li {
        display: inline-block;
        a {
          display: inline-block;
          padding: 0.75rem;
          color: $primary-color;
          &:hover {
            color: $secondary-color;
          }
          @include breakpoint (medium down) {
            padding: 0.5rem;
          }
          .fa {
            margin: 0 5px 0 0;
          }
          &.active {
            border-bottom: 2px solid $primary-color;
          }
        }
      }
    }
    &.header__top--active .header__cart .badge {
      top: 28px;
    }
    .header__cart {
      position: relative;
      @include breakpoint (small only) {
        padding: 0;
      }
      @include breakpoint(large) {
        margin-left: 40px;
        padding-top: 30px;
      }
      .fa {
        font-size: 1.125rem;
      }
      .badge {
        position: absolute;
        left: 35px;
        top: -5px;
        @include breakpoint(large) {
          left: 33px;
          top: 43px;
        }
        border: 0;
        padding: 5px;
        background-color: $color-primary;
        color: $white;
        min-width: 1.25rem;
        line-height: 1;
        font-weight: $global-weight-bold;
        border-radius: 50% !important;
      }
      .input-group {
        >:last-child {
           >* {
            border-radius: 0 6px 6px 0;
          }
        }
        .button {
          padding: 0.625rem;
          background-color: $color-secondary;
          transition: background-color 0.2s ease-in-out;
          &:hover {
            background-color: $color-tertiary;
          }
        }
      }
      input {
        background: $color-hover;
        border: 1px solid $color-hover;
        margin-right: 1px;
        color: $color-secondary;
      }
      .input-group-field {
        border-radius: 0 6px 6px 0;
      }
      div.cart__info {
        background: $color-hover;
        border: 1px solid $color-hover;
        margin-right: 1px;
        color: #3F5888;
        padding: 0.3rem;
        .amount {
          padding-left: 3rem;
          color: $color-primary;
        }
      }
    }
    &--active {
      position: fixed;
      max-height: 75px;
      .header-tagline {
        display: none;
      }
      .header__signin-apply {
        display: none;
      }
      @include breakpoint (medium down) {
        position: relative;
        height: auto;
        max-height: none;
        transition: none;
      }
      .header__logo {
        margin: 0.625rem 0;
        @include breakpoint (medium down) {
          margin: 1.25rem  0;
        }
        a {
          img {
            -webkit-transition: width 0.5s;
            -moz-transition: width 0.5s;
            -ms-transition: width 0.5s;
            -o-transition: width 0.5s;
            transition: width 0.5s;
            width: 150px;
            @include breakpoint (medium down) {
              width:250px;
            }
          }
        }
      }
      .header__search,
      .header__cart {
        padding: 1.063rem 0 0 0;
      }
      ul {
        margin: 0.625rem 0 0 0;
        @include breakpoint (medium down) {
          margin: 1.875rem 0 0 0;
        }
      }
    }
  } /* END header__top */

  .header-tagline {
    text-align: center;
    padding-top: 14px;
    h5 {
      font-family: 'Roboto', sans-serif;
      color: $color-secondary;
      letter-spacing: 1px;
    }
  }

  .header__search {
    .fa {
      font-size: 1.125rem; 
    }
    .input-group {
      >:last-child {
        >* {
          [data-intellisearch="active"] & {
            border-bottom-right-radius: 0 !important;
          }
        }
      }
      .button {
        padding: 0.625rem;
        transition: border-radius 0.7s;
        background-color: $color-secondary;
        transition: background-color 0.2s ease-in-out;
        &:hover {
          background-color: $color-tertiary;
        }
      }
    }
    input {
      background: white;
      border: 1px solid $color-secondary;
      margin-left: 1px;
      color: black;
      transition: border-radius 0.7s;
      @include placeholder {
        color: #02113d;
      }
      [data-intellisearch="active"] & {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border: 0px;
      }
      &:focus {
        border:0px;
        background-color: $white;
        transition: none;
      }

    }
    span {
      display: inline-block;
      margin: 0 0 0 0.3125rem;
    }
    #js-frm-site-search {
      position: relative;
      z-index: 4;
    }
    .main-header__search-results {
      position: absolute;
      z-index: 4;
      //width:100%;
      background: $white;
      padding: 1.25rem 0 0 0;
      display: none;
      top: -16px;
      left: 1px;
      right: 1px;
      h3 {
        font-size: 1.125rem;
        padding: 0 0.75rem;
      }
      ul {
        list-style-type: none;
        text-align: left;
        margin: 0;
        float: none;
        li {
          display: block;
          padding: 0 0.5rem;
          &:hover,
          &:focus {
            background: $light-gray;
          }
          a {
            display: block;
            padding: 0.3125rem;
            margin: 0 0 0.5rem 0;
            font-size: 0.875rem;
            color: $color-primary;
            &:hover,
            &:focus {
              background: $light-gray;
            }
            b {
              font-weight: $global-weight-normal;
            }
            figure {
              float: left;
              width:50px;
              margin: 0 0.9375rem 0 0;
              @include breakpoint (small) {
                display: none;
              }
              @include breakpoint (large up) {
                display: block;
              }
              img {
                margin: 0 auto;
              }
            }
            div {
              p {
                margin: 0;
                font-size: 0.875rem;
                line-height: 1.5rem;
              }
              h4 {
                margin: 0;
                font-size: 0.875rem;
                color: $color-primary;
              }
            }
          }
        }
      }
      .__show-all {
        display: block;
        padding: 0.5rem;
        background: $color-primary;
        color: $white;
        font-weight: $global-weight-normal;
        text-align: center;
        font-size: 0.875rem;
        b {
          font-weight: $global-weight-normal;
        }
      }
    }
  } /* END header__search */

  
  .header__middle { 
    background: $color-primary;
    padding: 0;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 2;
    @include breakpoint (medium down) {
      float: none;
    }
    @include breakpoint (large) {
      &.header__search__active {
        padding: 0;
      }
      &.header__middle--active {
        position: fixed;
        top: 75px;
        .header__master__categories {
          li a {
            padding: 0.425rem 0.9375rem !important;
          }
        }
      }
    }
    .header__master__categories {
      .menu {
        .is-active {
          background: $color-hover;
          a {
            color: #02113d !important;
          }
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
        float: left;
        width:100%;
        li {
          a {
            display: block;
            color: $white;
            font-weight: $global-weight-normal;
          }
        }
      }
      .main-nav-ul {
        display: table;
        @include breakpoint (medium down) {
          margin: 0.9375rem 0 0 0;
        }
        li {
          &.dropdown {
            display: table-cell;
            height: 100%;
            vertical-align: middle;
            text-transform: uppercase;
            text-align: center;
            width:12.5%;
            transition: background 0.4s;
            font-family: 'Roboto', sans-serif;
            letter-spacing: 1px;
            @include breakpoint(large only) {
              font-size: 13px;
            }
            &:hover {
              background: $color-hover;
              a {
                color: $color-secondary;
              }
            }
            @include breakpoint (medium down) {
              width:100%;
              display: block;
              border-bottom: 1px solid $color-hover;
              border-right: 0px;
              text-align: left;
              &:last-child {
                border-bottom: 0px;
              }
            }
            li {
              padding: 6px 15px;
              @include breakpoint (medium down) {
                padding: 6px;
              }
            }
            a {
              display: block;
              color: white;
              font-weight: 400;
              transition: color 0.2s;
              letter-spacing: 1px;
            }
            .droplink {
              display: block;
              position: relative;
              font-size: 15px;
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
          &.dropdown-1 {
            @include breakpoint (medium  down) {
              border-left:0px;
            }
          }
        }
      }
    }

    .droplink-2 {
      cursor: none;
    }
  
    .category__menu {
      display: none;
      .product__category__box {
        box-shadow:none;
        margin: 1.25rem 0 0 0;
        background: transparent;
        ul {
          li {
            padding: 10px 0;
            border-bottom: 1px solid $white;
            &:last-child {
              border-bottom: 0px;
            }
            a {
              color: $white;
              font-weight: $global-weight-bold;
            }
          }
        }
      }
    }
    .mobile__cat__menu__wrapper {
      margin: 0;
    }
    .header__middle__account__nav {
      text-align: right;
      .dropdown{
        &.button {
          background: transparent;
          padding:0;
          margin: 0;
          position: relative;
          font-weight: $global-weight-bold;
        }
      }
      .dropdown-menu {
        width:100px;
        text-align: left;
        top:40px;
        right: 10px;
        position: absolute;
        z-index: 10;
        background: $medium-gray;
        padding: 1rem;
        display: none;
        ul {
          list-style-type: none;
          margin: 0;
          li {
            display: block;
          }
        }
      }
    }
    /* Mobile Menu Animation */
    .mobile__cat__menu__icon {
      display: inline-block;
      position: relative;
      overflow: hidden;
      margin: 0;
      padding: 0;
      width: 50px;
      height: 50px;
      font-size: 0;
      text-indent: -9999px;
      appearance: none;
      box-shadow: none;
      border-radius: none;
      border: none;
      cursor: pointer;
      &:focus {
        outline:none;
      }
      span {
        display: block;
        position: absolute;
        top: 23px;
        left: 5px;
        right: 7px;
        height: 5px;
        background: white;
        &::before,
        &::after {
          position: absolute;
          display: block;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: #fff;
          content: "";
        }
        &::before {
          top: -12px;
        }
        &::after {
          bottom: -12px;
        }
      }
      &--open {
        span {
          &::before,
          &::after {
            -webkit-transition-duration: 0.3s, 0.3s;
            -moz-transition-duration: 0.3s, 0.3s;
            -ms-transition-duration: 0.3s, 0.3s;
            -o-transition-duration: 0.3s, 0.3s;
            transition-duration: 0.3s, 0.3s;
            -webkit-transition-delay: 0.3s, 0.3s;
            -moz-transition-delay: 0.3s, 0.3s;
            -ms-transition-delay: 0.3s, 0.3s;
            -o-transition-delay: 0.3s, 0.3s;
            transition-delay: 0.3s, 0s;
          }
          &::before {
            -webkit-transition-property: top, transform;
            -moz-transition-property: top, transform;
            -ms-transition-property: top, transform;
            -o-transition-property: top, transform;
            transition-property: top, transform;
          }
          &::after {
            -webkit-transition-property: bottom, transform;
            -moz-transition-property: bottom, transform;
            -ms-transition-property: bottom, transform;
            -o-transition-property: bottom, transform;
            transition-property: bottom, transform;
          }
        }
        &.is-active {
          span {
            background: none; 
            &::before {
              top: 0;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &::after {
              bottom: 0;
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
            &::before,
            &::after {
              -webkit-transition-delay: 0s, 0.3s;
              -moz-transition-delay: 0s, 0.3s;
              -ms-transition-delay: 0s, 0.3s;
              -o-transition-delay: 0s, 0.3s;
              transition-delay: 0s, 0.3s;
            }
          }
        }
      }
    }
    /* Mobile Menu Animation */
    button {
      margin-left: 2px;
    }
    .mobile__menu__cat__title {
      color: $white;
      font-weight: $global-weight-bold;
      display: inline-block;
      margin:21px 0 0 7px;
      text-align: left;
      line-height: 1;
      vertical-align: top;
      font-family: 'Roboto', sans-serif;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }

  .header__info {
    padding: 1rem 0;
    float: left;
    width:100%;
    background: $white;
    p {
      margin: 0;
      text-align: center;
      @include breakpoint (medium down) {
        font-size: 0.875rem;
      }
    }
  }
  
}

@include breakpoint(small only) {
  .is-dropdown-submenu {
    position: relative;
  }
}

.home__banner__section {
  padding-top: 15px;
  @include breakpoint(medium) {
    padding-top: 25px;
  }
}

.main-content {
  min-height: 700px;
}

.main-content.main-content--fixed-nav {
  @include breakpoint(large) {
    padding-top: 119px;
  }
}

li.is-submenu-item {
  a {
    color: #3adb76;
  }
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
  right: 0;
  left: auto;
}

  // HEADER MOBILE //

.header__middle--inactive {
  position: relative;
  float: left;
  width: 100%;
}

.header__middle--active {
  position: fixed;
  top: 0;
}


.dropdown.menu>li.is-dropdown-submenu-parent>a::after {
  border-color: $secondary-color transparent;
}

/*------------------------------------*\
    #HOME
\*------------------------------------*/
section {
  &.product-home {
    &__carousel {
      //box-shadow: 0 0 6px 0 #E2E2E2;
      position: relative;
      height: 300px;
      @include breakpoint (medium up) {
        height: 500px;
      }
      overflow: hidden;
      .product-home__carousel-internal-wrapper {
        position: relative;
        height: inherit;
        margin: 0 auto;
        overflow: hidden;
        .orbit {
          height: inherit;
          overflow: hidden;
          max-height: 900px;
          .orbit-container {
            height: inherit !important;
            width: 100%;
            overflow: hidden;
            max-height: 900px;
            position: relative;
          }
          li {
            max-height: inherit !important;
            > a {
              display: block;
              height: auto;
              .orbit-image {
                background-repeat: no-repeat;
                background-position: center top;
                background-size: cover;
                height: 300px;
                @include breakpoint (medium up) {
                  height: 600px;
                }
                .orbit-image-module-block {
                  display: table;
                  height: 300px;
                  @include breakpoint (medium up) {
                    height: 500px;
                  }
                  text-align: center;
                  width:100%;
                  .orbit-image-module {
                    display: table-cell;
                    vertical-align: middle;
                    h1 {
                      color: $white;
                      background: rgba(255, 255, 255, 0.5);
                      padding: 1.5rem;
                      text-shadow: 1px 1px 25px rgba(0,0,0,0.6);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .orbit-bullets {
      position: absolute;
      bottom: 6px;
      left: 0;
      right: 0;
      margin: 0 auto;
      button {
        width: 0.9375rem;
        height: 0.9375rem;
        background: $white;
        border-radius: 0px;
        &.is-active {
          background: $site-brand-color-1;
        }
      }
    }
  }
}

.orbit-next, .orbit-previous {
  z-index: 2;
}
.orbit-figure {
  margin-top: 8px;
}

.orbit-text {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  color: $color-secondary;
  letter-spacing: 2px;
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  top: 35%;
  margin: 0 auto;
  float: left;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  @include breakpoint(medium) {
    font-size: 30px;
  }
  @include breakpoint(large) {
    font-size: 40px;
  }
}

.home__banner__section__top {
  .orbit {
    max-height: 444px;
    overflow: hidden;
    @include breakpoint(medium) {
      max-height: 309px;
    }
    @include breakpoint(large) {
      max-height: 371px;
    }
    @include breakpoint(xlarge) {
      max-height: 444px;
    }
    @include breakpoint(xxlarge) {
      max-height: 458px;
    }
  }
}

.orbit-slide {
  max-height: inherit !important;
  overflow: hidden;
  width: 100%;
}

.home__info {
  margin: 4.5rem 0;
  padding: 1rem;
}

.home__info {
  p {
    font-family: "Open Sans", sans-serif;
    color: $color-secondary;
    letter-spacing: 1px;
    @include breakpoint(xlarge) {
      padding-right: 100px;
      padding-left: 200px;
    }
    @include breakpoint(xxlarge) {
      padding-left: 200px;
    }
  }
}

.home__info__header {
  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    letter-spacing: 4px;
    font-weight: bold;
    margin-bottom: 40px;
    color: $color-secondary;
    @include breakpoint(medium) {
      font-size: 30px;
    }
    @include breakpoint(large) {
      font-size: 36px;
    }
    @include breakpoint(xlarge) {
      padding-left: 200px;
    }
    @include breakpoint(xxlarge) {
      padding-left: 200px;
    }
  }
}

#home__products {
  float: left;
  width:100%;
  .tabs-panel {
    float: left;
    width: 100%;
  }
}

.home__module {
  //background: $white;
  background: #f1f1f1;
  margin: 1.5rem 0;
  //box-shadow: 0 0 5px 0 #E2E2E2;
  &__img {
    background-repeat: no-repeat;
    background-position: center top;
    height: 230px;
    background-size: cover;
  }
  &__title,
  &__info {
    display: table;
    height: 150px;
    width:100%;
    @include breakpoint (small only) {
      display: block;
      height: auto;
    }
    @include breakpoint (medium only) {
      height: 200px;
    }
    p,
    h4 {
      display: table-cell;
      vertical-align: middle;
      margin: 0;
      padding: 1rem;
      @include breakpoint (small only) {
        display: block;
      }
    }
    p {
      color: $body-font-color;
    }
  }
  &__title {
    height: 50px;
    text-align: center;
    @include breakpoint (small only) {
      height: auto;
    }
    @include breakpoint (medium only) {
      height: 105px;
    }
  }
}

.welcome-wrapper {
  background-color: #f1f1f1;
  margin-top: 25px;
}


/*------------------------------------*\
    #PRODUCT DETAIL
\*------------------------------------*/
.product__spacing {
  //padding: 1.25rem;
  //margin: 0 0 1.875rem 0;
  padding: inherit;
  margin: inherit;
}

.product {

  .product__buy__form {
    .input-group.product-content {
      input {
        width: 100%;
      }
      .button {
        margin: 0;
      }
    }
  }

  &__title {
    float: left;
    padding: 1rem 1rem 1rem 0;
    h4 {
      font-weight: bold;
    }
  }

  &__images {
    //min-height: 646px;
    @extend .product__spacing;
    .orbit-container {
      height: inherit !important;
      width: 100%;
      max-height: 900px;
      overflow: hidden;
      // max-height: 488px;
    }
    .orbit-bullets {
      text-align: left;
      button {
        width: auto;
        height: auto;
        margin: 0 9px 12px 0;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  .product__thumbnails {
    width: 80px;
    height: 80px;
  }

  &.product__favourites {
    .product__images {
      min-height: 350px;
    }
  }

  .product__description,
  .product__content__delivery,
  .product__buy,
  .products__similar,
  .product__specs {
    @extend .product__spacing;
    h4 {
      border-bottom: 1px solid $medium-gray;
      padding: 0 0 0.625rem 0;
      margin: 0 0 0.9375rem 0;
    }
    h5 {
      color: $color-secondary;
    }
  }

  .product__description {
    padding-top: 20px;
    padding-bottom: 20px;
    table {
      tbody {
        border:0px;
        tr {
          &:nth-child(even) {
            background: $white;
          }
        }
        td {
          padding: 0;
        }
      }
    }
    dl.product__specs {
      dt {
        color: $color-secondary;
        font-weight: bold;
        display: inline;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 1px;
      }
      dd {
        display: inline;
        padding-left: 7px;
        }
      }
    }
  }

  .products__similar {
    margin: 0;
    .products__tile {
      margin: 0 0 1.5rem 0;
      &:last-child {
        margin: 0;
      }
    }
  }

  .product__buy {
    position: relative;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;
    width: 100%;
    form {
      margin: 0.625rem 0 0 0;
      padding-right: 10px;
      padding-left: 10px;
      @include breakpoint(large only) {
        padding-left: 0;
        padding-right: 0;
      }
      @include breakpoint(medium only) {
        padding-left: 0;
        padding-right: 0;
      }
      input {
        width: 66%;
        border-radius: 0;
        text-align: center;
        margin: 0;
        height: 41px;
        display: inline-block;
        float: left;
        border: solid 1px ;
        &:focus {
          border: 1px solid $color-secondary;
        }
      }
      .button {
        width: 100%;
        margin: 20px 0 0 0;
        height: 41px;
      }
      a {
        &.product__decrease,
        &.product__increase {
          margin: 0;
          /*display: inline-block;
          float: left;
          width: 17%;
          padding: 5px;
          line-height: 1.75rem;*/
          background: $site-brand-color-1;
          color: white;
        }
      }
      p {
        &.active {
          background: #aff1a3;
          background:$site-brand-color-1;
          padding: 1rem;
          margin: 1.15rem 0 0 0;
        }
      }
    }
    .product__price__standard {
      color: $color-secondary;
    }
    .product__content__price {
      p {
        //margin: 1rem 0 1.5rem 0;
        font-size: 2.6rem;
        padding-top: 20px;
        padding-left: 10px;
      }
      .__standard-price {
        font-weight: $global-weight-bold;
        display: inline-block;
        text-decoration: line-through;
        color: $primary-color;
      }
      .__pound-symbol {
        font-size: 1.6rem;
      }
      .__ex-vat {
        font-size: 1rem;
        color: $body-font-color;
      }
      small {
        color: $primary-color;
        font-weight: $global-weight-normal;
      }
      .__sale-price {
        color: $body-font-color;
        // font-size: 1.25rem;
        font-weight: $global-weight-bold;
      }
    }
  }

  .product__specs {
    h4 {
      margin: 0;
    }
    table {
      margin: 0;
      tbody {
        border:0px;
        tr {
          &:nth-child(even) {
            background-color: $white;
          }
          border-bottom: 1px solid $medium-gray;
          &:last-child {
            border-bottom: 0px;
          }
          td {
            b {
              font-weight: $global-weight-bold;
            }
          }
        }
      }
    }
  }

  .product__sharing {
    display: table;
    .__share {
      display: table-cell;
      vertical-align: top;
      padding: 12px 0 5px 5px;
      .fb-like {
        span {
          height: 28px !important;
        }
      }
    }
  }

  input [type=checkbox] {

  }


/*------------------------------------*\
    #PRODUCT CATEGORY BOX
\*------------------------------------*/

.breadcrumbs--fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 119px;
  z-index: 1;
}

ul.breadcrumbs {
  li {
    a {
      font-size: 9px;
      color: $color-secondary;
      @include breakpoint(medium) {
        font-size: 14px;
      }
    }
  }
}

.product__category-control {
  margin-top: 60px;
}

.product__category__row {
  //border-top: 1px solid $light-gray;
  .product__category__col {
    min-height: 290px;
    //border-right: 1px solid $light-gray;
    //border-bottom: 1px solid $light-gray;
  }
}
.product__category__content {
  float: left;
  width:100%;
  &__intro {
    padding: 1rem;
  }
  h3 {
    border-bottom: 1px solid $light-gray;
    margin: 0;
    padding: 0.75rem;
    color: $primary-color;
  }
  .product__category {
    //min-height: 430px;
    position: relative;
    border-top: 1px solid #f1f1f1;
    @include breakpoint (small only) {
      min-height: unset;
    }
    a {
      display: block;
      font-size: 0.875rem;
      line-height: 1.25rem;
      &__title__name {
        height: 70px;
        @include breakpoint (small only) {
          height: auto;
          padding: 0 0 1rem 0;
        }
      }
    }
    .product__cart-qty,
    .js-products__cart-qty {
      -webkit-transition: all color .8s ease-in-out ease;
      -moz-transition: all color .8s ease-in-out ease;
      -ms-transition: all color .8s ease-in-out ease;
      -o-transition: all color .8s ease-in-out ease;
      transition: all color .8s ease-in-out ease;
      position: absolute;
      top: 11px;
      right: 32px;
      width: 65px;
      height: 28px;
      color: $white;
      padding: 5px;
      background-color: $primary-color;
      span {
        display: inline-block;
        margin-left: 0.5rem;
        font-size: 1rem;
        font-weight: $global-weight-bold;
        font-family: $body-font-family;
      }
    }
    .products__info {
      padding: 2.8rem 0 0 0;
      &--noPadding {
        padding: 0;
          .products__price-standard {
            padding: 1rem 0 0 0;
            margin-right: 10px;
            margin-bottom: 10px;
          }
      }
      &--active {
        background: #aff1a3; //- green background option available but not being used as it doesn't look good, using blue colour instead */
        //background: $site-brand-color-1;
      }
      &--buy {
        //background: $site-brand-color-1;
        //margin-right: -(map_get($grid-column-gutter, medium)) - 1; // not sure what's been done here, but I had to add 1px to make it fit - ant
        @include breakpoint(large) {
          //min-height: 200px;
        }
      }
    }
    .products__availability,
    .products__meta {
      margin: 0;
      font-size: 13px;
      line-height:1.25rem;
      tr:first-child {
        td:not(:first-child) {
          color: $primary-color;
        }
      }
      td {
        padding: 0.1rem 0 !important;
        &:first-child {
          width: 25%;
        }
      }
    }
    .products__meta.products__search {
      td {
        &:first-child {
          width: 50%;
        }
      }
    }
    .products__meta.category__products {
      td {
        &:first-child {
          width: 25%;
          @include breakpoint(medium) {
            width: 50%;
          }
          @include breakpoint(xlarge) {
            width: 25%;
          }
        }
      }
    }
    .products__price-standard {
      text-align: right;
      margin: 5px 0 0;
      font-size: 2.6rem;
      @include breakpoint(large only) {
        font-size: 33px;
      }
      color: $color-secondary;
      .__pound-symbol {
        font-size: 1.6rem;
      }
      .__ex-vat {
        font-size: 1rem;
        color: #7a7a7a;
      }
    }
    .products__price-sale {
      margin: 0;
      line-height: 25px;
      color: $color-secondary;
      .__standard-price {
        font-size: 0.6875rem;
        font-weight: $global-weight-bold;
        text-decoration: line-through;
        color: $color-secondary;
      }
      .__sale-price {
        font-size: 1.125rem;
        font-weight: $global-weight-bold;
      }
    }
    .products__info {
      table {
        tbody {
          border:0px;
          tr {
            &:nth-child(even) {
              background-color: $white;
            }
          }
          td {
            padding: 0;
          }
        }
      }
      .buy-product {
        margin: 0.625rem 0 0 0;
        a {
          line-height: 0.9375rem;
        }
        p {
          font-size: 0.75rem;
          text-align: center;
          a {
            font-size: 0.75rem;
            display: inline-block;
          }
        }
        .button {
          width:100%;
          display: block;
        }
        .input-group {
          margin: 0;
          .product__increase,
          .product__decrease {
            /*display: inline-block;
            float: left;
            width: 17%;
            height: 2.5rem;
            line-height: 1.75rem;
            padding: 5px;*/
            background: $site-brand-color-1;
            color: white;
            margin: 0;
            height: 2.4rem;
            font-size: 1rem;
          }
          .input-group-button {
            .button {
              &.primary {
                height: 2.4rem;
                font-size: 1rem;
              }
            }
          }
        }
        .input-group-field {
          //height:2.5rem;
          border-color: $site-brand-color-1;
          //float: left;
          //display: inline-block;
          //width: 66%;
          text-align: center;
        }
        .js-buy-product__cart-ctl {
          display: none !important;
        }
        .js-buy-product__normal {
          //display: block !important;
        }
      }
      .button-group {
        margin: 0;
      }
    }
  }
  &.product__category__content__II {
    .product__category {
      min-height: 0px;
      float: left;
      width: 100%;
      @include breakpoint(xlarge) {
        min-height: 240px;
      }
      a {
        font-size: 1.3625rem;
        line-height: 1.5rem;
        margin: 0 0 0.75rem 0;
        font-weight: $global-weight-bold;
        color: $color-secondary;//#003b57;
        &__title__name {
          height: auto;
        }
      }
    }
    .product__category__title__image  {
      min-height: unset;
      margin-top: 1rem;
    }
  }
  .pagination {
    //margin-top:1rem;
    //margin-right: 1rem;
  }
}

ul.pagination.float-right {
  margin-top: 20px;
}

.product__subcategories {
  &__list--names {
    .column {
      .callout {
        padding:0.625rem;
        border:0px;
        min-height: 52px;
        box-shadow: 0 0 5px 0 #E2E2E2;
        a {
          .fa {
            float: right;
            margin: 8px 0 0 0;
          }
        }
      }
    }
  }
  .product__subcategories__list--images {
    .column {
      .callout {
        border:0px;
        padding: 0;
        box-shadow: 0 0 5px 0 #E2E2E2;
        .product__subcategory__title {
          display: table;
          width: 100%;
          padding: 0 0.75rem;
          height: 80px;
          p {
            font-size: 0.875rem;
            line-height: 1.25rem;
            display: table-cell;
            vertical-align: middle;
            margin: 0;
          }
        }
      }
    }
  }
}

.product__category__products__sorting {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  .dropdown {
    &.menu {
      //margin: 1rem 0 1rem 1rem;
      > li {
        &.is-dropdown-submenu-parent{
          > a {
            &::after {
              border-color: $white transparent transparent;
            }
          }
          .is-dropdown-submenu {
            min-width: 171px;
            left: auto;
          }
        }
      }
    }
  }
}

header .header__middle .header__master__categories .main-nav-ul li.dropdown {
  position: relative;
}

ul.seasonal-menu {
  right: 0;
  left: auto;

  li {
    a {
      color: $secondary-color;
    }
  }
}

.header__middle--inactive {
  .dropdown.menu > li.opens-right > .is-dropdown-submenu {
    @include breakpoint(large) {
      position: absolute;
      top: 50px;
      box-shadow: 1px 1px 1px 1px $color-hover;

    }
  }
}

.header__middle--active {
  .dropdown.menu > li.opens-right > .is-dropdown-submenu {
    @include breakpoint(large) {
      position: absolute;
      top: 44px;
      box-shadow: 1px 1px 1px 1px $color-hover;
    }
  }
}

a.dropbutton {
  font-size: 12px;
  padding: 0 0;
}



.product__category__title__image {
  min-height: 220px;
  text-align: center;
  @include breakpoint (small only) {
    min-height: auto;
  }
}

.product__category__products__list-ctrl {
  // padding: 10px 10px 10px;
  float: left;
  width: 100%;
  .product__category__products__info {
    p {
      color: $color-secondary;
      margin-top: 20px;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 2px;
    }
  }
}

.product__category__box {
  @extend .background;
  @extend .background--white;
  //box-shadow: 0 0 5px 0 #E2E2E2;
  margin: 55px 0 1.25rem 0;
  border-right: 1px solid #f1f1f1;
  ul {
    margin: 0;
    list-style-type: none;
    li {
      line-height: 1rem;
      color: $color-secondary;
      &:hover {
        font-weight: 700;
        background-color: #f1f1f1;
      }
      &.category-box__title {
        font-weight: bold;
        background-color: $color-tertiary;
        color: white;
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        letter-spacing: 2px;
        text-decoration: none;
        padding: 0.75rem;
      }
      &.category-box__last {
        //border-bottom: 1px solid #e4e4e4
        &:hover {
          background-color: white;
        }
      }
      a {
        color: $color-secondary;
        display: block;
        font-size: 0.875rem;
        padding: 0.75rem;
      }
      .product__category__box__items {
        a {
          padding: 0 0.75rem;
          &:hover {
            background: $site-brand-color-1;
            color: $site-brand-color-2;
          }
        }
      }
    } 
  } 
}

.product__category__box {
  li.catOuterTitle {
    background: #195071;
    &:hover {
      background: #195071;
    }
    a {
      color: white;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      &:hover {
        color: white;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
      }
    }
  }
}

.product__title {
  letter-spacing: 1px;
  h4{
    color: $color-secondary;
  }
}

.account__content {
  h4 {
    font-size: 30px;
    letter-spacing: 2px;
    color: $color-secondary;
  }
}


/*------------------------------------*\
    #CART
\*------------------------------------*/

.cart {
  h5 {
    margin: 1.5rem 0;
    border-bottom: 2px solid $medium-gray;
    padding: 0 0 0.625rem 0;
  }
  form {
    float: left;
    width: 100%;
    label {
      margin: 0 0 0.3125rem 0;
    }
    input,
    select {
      border: 1px solid $medium-gray;
      &:focus {
        border: 1px solid $black;
      }
    }
  }
  .account__login__register {
    margin: 2.5rem 0 0 0;  
  }
}
.cart__content {
  position: relative;
  float: left;
  width:100%;

  table {
    font-size: 0.875rem;
    line-height:1.5rem;
    color: $color-secondary;
    thead {
      background: $white;
      border:0px;
      border-bottom: 2px solid $medium-gray;

      tr {
        th {
          &.cart__item-qty {
            text-align: center;
            @include breakpoint (medium down) {
              text-align: left;
            }
          }
        }
      }
    }
    tbody {
      border: 0px;
      tr.cartErrorMessage {
        td {
          text-align: center;
          font-weight: bold;
          background: rgba(255, 0, 0, 0.5);
          margin-top: 1px;
        }
      }
      tr.cartWarningMessage {
        td {
          text-align: center;
          font-weight: bold;
          background: rgba(255, 165, 0, 0.5);
          margin-top: 1px;
        }
      }
      tr {
        &:nth-child(even) {
          background: $white;
        }
        border-bottom: 1px solid $medium-gray;
        td {
          &.cart__item-qty {
            text-align: center;
            @include breakpoint (medium down) {
              text-align: left;
            }
          }
        }
      }
      a {
        color: $color-secondary;
      }
    }
    .cart__item-qty {
      a {
        span {
          display: inline-block;
          font-size: 1.125rem;
          font-weight: $global-weight-bold;
          color: $black;
        }
      }
      input {
        display: inline-block;
        width:70%;
        border:0px;
        text-align: center;
        background: $color-hover;
        margin: 0;
        @include breakpoint (medium down) {
          width: auto;
        }
      }
    }
  }
  .cart__content__goods {
    table {
      font-size: 1rem;
      margin: 30px 0 40px 0;
      tr {
        td {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  
  .cart__content__deivery,
  .cart__content__shipping {
    margin: 1.5rem 0 0 0;
  }

  .cart__content__shipping {
    margin: 1.5rem 0 1.875rem 0;
    h5 {
      margin: 0 0 1.875rem 0; 
    }
  }

  .cart__content__deivery {
    p {
      margin: 30px 0 10px 0;
    }
    select {
      border: 1px solid $medium-gray;
    }
  }

  .cart__content__address {
    margin: 0 0 1.5rem 0;
    border-bottom: 2px solid $medium-gray;
    margin-right: 21px;
    h5 {
      margin: 0 0 1.5rem 0;
      font-size: 22px;
      letter-spacing: 1px;
    }
  }

  .cart-address-selection {
    margin-bottom: 50px;
    margin-right: 20px;
  }

  .cart__content__address {
    input[type="submit"] {
      background: transparent;
      border:0px;
      -webkit-appearance: none;
      color: $primary-color;
      padding:0;
    }
  }

  &.cart__payment {
    margin-bottom: 1.75rem;
    h5 {
      border-bottom: 1px solid $medium-gray;
      padding: 0 0 0.625rem 0;
      margin: 1.75rem 0;
    }
  }
}

.cart__summary {
  //margin: 1.75rem 0 0 0;
  // position: fixed;
  @include breakpoint (medium down) {
    position: relative;
  }
  h5 {
    margin-top:0;
    color: $body-font-color;
    font-weight: $global-weight-bold;
    border-bottom: 2px solid $medium-gray;
    padding: 0 0 0.625rem 0;
  }
  p {
    text-align: left;
    span {
      display: inline-block;
      text-align: right;
    }
  }
}

.quick-add-to-cart {
  background: $medium-gray;
  padding: 16px 20px 0;
  margin-bottom: 20px;
  p.quick-add-to-cart__label {
      margin: 5px;
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
      color: $color-primary;
      letter-spacing: 2px;
      margin-bottom: 15px;
    text-transform: uppercase;
  }
}

.wrapper-btn {
  a {
    color: $white;
  }
  input[type="submit"],
  .optBtn {
    border:0px;
    -webkit-appearance: none;
    &:focus {
      border:0px;
    }
  }
  .optBtn {
    background: $color-tertiary;
    font-weight: 700;
    padding: 0.85em 1em;
    font-size: 1rem;
    display: inline-block;
  }
}

.payment__successful__callout {
  text-align: center;
}

.payment__error__message {
  text-align: center;
}

.errorContainer #goog-fixurl input[type="submit"] {
  float: none;
}

.product__category__content .product__category .products__info {
  .callout.secondary {
    border-style: none;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    position: absolute;
    top: 0;
    margin-bottom: 0;
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: #d7e7f1;
    @include breakpoint(medium) {
      position: relative;
      float: left;
      margin-top: -44px;
    }
    @include breakpoint(xlarge) {
      margin-left: -10px;
    }
    @include breakpoint(xxlarge) {
      margin-top: -44px;
    }
    p {
      color: black;
      font-size: 13px;
      font-weight: bold;
      @include breakpoint(xlarge only) {
        font-size: 11px;
      }
    }
  }
}

.product__category__content .product__category {
  .callout.secondary {
    border-style: none;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    position: absolute;
    top: 0;
    margin-bottom: 0;
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: #d7e7f1;
    @include breakpoint(medium) {
      position: relative;
      float: left;
    }
    @include breakpoint(xlarge) {
      margin-left: -10px;
      margin-top: 0;
    }
    p {
      color: black;
      font-size: 13px;
      font-weight: bold;
      @include breakpoint(large only) {
        font-size: 11px;
      }
      @include breakpoint(xlarge only) {
        font-size: 11px;
      }
    }
  }
}

.address-select-wrapper {
  position: relative;
  float: left;
  width: 100%;
  h6 {
    font-size: 20px;
  }
}

/*------------------------------------*\
    #PRODUCT LAUNCHPAD
\*------------------------------------*/

.latest__news {
  padding: 1rem;
  float: left;
  width:100%;
  margin: 0 0 2rem 0;
  &__intro {
    padding: 0 0 1rem 0;
    margin-top: 20px;
    h4 {
      color: $color-secondary;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 2px;
      font-weight: bold;
      font-size: 30px;
    }
  }
  &__blog__posts {
    float: left;
    width: 100%;
    margin: 0 0 1.5rem 0;
    a {
      display: block;
    }
    .latest__news__blog__post {
      margin: 1.5rem 0 0 0;
      padding: 1rem;
    }
  }
}

/// Account Pages
.account {
  .account__home__module {

  }

  .viewOrderDetails, .hideOrderDetails {
    cursor: pointer;
  }

  .orderDetailsContainer {
    background-color: #137bb8!important;
  }

}

.account__title {
  text-align: left;
  margin-bottom: 20px;
  h4 {
    letter-spacing: 2px;
    color: $color-secondary;
  }
}

.generic-block-module.welcome-module {
  text-align: center;
  h5 {
    font-weight: 200;
    span {
      font-weight: 700;
    }
  }
  a {
    color: $color-secondary;
    font-size: 15px;
  }
  i {
    font-size: 18px;
    color: $color-secondary;
  }
}

.welcome-module {
  margin-bottom: 5px;
}

.account-module {
  margin-bottom: 5px;
  &:hover {
    background-color: $color-hover;
    p {
      font-weight: bold;
    }
  }
  p {
    margin-left: 8px;
    font-size: 14px;
    display: inline;
    color: $color-secondary;
    transition: all .2s ease;
    @include breakpoint(large only) {
      font-size: 12px;
      margin-left: 5px;
    }
  }
}

.generic-block-module ul li a {
  color: #02113d;
}

input.button.invoice-button {
  @include breakpoint(medium) {
    margin-top: 24px;
  }
}

.button-group.invoice-button2 {
  margin-bottom: 0px;
}


/*------------------------------------*\
    #SORT BY 
\*------------------------------------*/

label {
  color: #02113d;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
}

select {
  border: 1px solid #e0e0e0;
  color: #463934;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
  background-color: #fafafb;
  font-weight: 400;
}

/*------------------------------------*\
    #HOMEPAGE CARDS
\*------------------------------------*/

.home__banner__info__wrapper {
  background-color: #f1f1f1;
}

.home__banner__section__top {
  @include breakpoint(medium) {
    margin-bottom: 15px;
  }
}

.home__banner__info1, .home__banner__info2, .home__banner__info3 {
  .customer-service-icon, .lorry2-icon, .stock-icon {
    display: inline;
    position: relative;
    float: left;
  }
  h5 {
    display: inline;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #02113d;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 14px;
  }
}

.home__banner__info1 {
  padding-top: 19px;
  h5 {
    margin-left: 14px;
  }
  p {
    margin-left: 55px;
    margin-right: 65px;
    margin-top: -2px;
    @include breakpoint(xlarge) {
      margin-right: 166px;
    }
  }
}

.lorry2-icon {
  margin-top: 11px;
}

.home__banner__info2 {
  padding-top: 19px;
  h5 {
    margin-left: 14px;
  }
  p {
    margin-left: 74px;
    margin-right: 55px;
    margin-top: -2px;
    @include breakpoint(xlarge) {
      margin-right: 145px;
    }
  }
}

.home__banner__info3 {
  padding-top: 19px;
  h5 {
    margin-left: 14px;
  }
  p {
    margin-left: 60px;
    margin-right: 55px;
    margin-top: -2px;
    @include breakpoint(xlarge) {
      margin-right: 161px;
    }
  }
}


.card-1, .card-2, .card-3, .card-4 {
  margin-top: 8px;
  margin-bottom: 5px;
  text-align: center;
}

/*------------------------------------*\
    #HOMEPAGE ORBIT
\*------------------------------------*/

.launchpad-homepage-header {
  margin-top: 40px;
  margin-bottom: 40px;
  @include breakpoint(medium only) {
    margin-bottom: 0;
    margin-top: 50px;
  }
  @include breakpoint(large) {
    padding-top: 75px;
  }
  .launchpad-homepage-header2 {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
    color: #1179b8;
    margin-bottom: 6px;
    margin-left: 20px;
    @include breakpoint(medium only) {
      font-size: 25px;
    }
    @include breakpoint(large) {
      font-size: 37px;
    }
  }
}
.launchpad-homepage-header-underline {
  background-color: #f1f1f1;
  height: 2px;
  margin-bottom: 40px;
  margin-left: 20px;
  @include breakpoint(medium) {
    margin-bottom: 100px;
  }
}

.launchpad-homepage-section {
  margin-bottom: 30px;
  margin-top: 30px;
  @include breakpoint(medium) {
    margin-bottom: 60px;
    margin-top: 40px;
  }
  @include breakpoint(large) {
    margin-bottom: 130px;
    margin-top: 50px;
  }
}

.homepage-orbit {
  text-align: center;
  h5 {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
    color: #02113d;
    font-size: 22px;
    margin-bottom: 15px;
  }
  p {
    font-family: "Open Sans", sans-serif;
    margin-left: 20px;
    margin-right: 20px;
  }
  a {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    margin-bottom: 30px;
    &:hover {
      color: #02113d;
    }
  }
}

.homepage-orbit-images {
  margin-top: 35px;
}

.product {
  .row.full-width {
    position: relative;
    @include breakpoint(medium) {
      position: absolute;
    }
  }
}

/*------------------------------------*\
    #FOOTER
\*------------------------------------*/
footer {
  float: left;
  width:100%;
  .footer__top {
    background-image: url("../img/footer-top-background2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    float: left;
    width: 100%;
    padding: 1.25rem 0;
    padding-top: 75px;
    padding-bottom: 75px;
    .footer__info {
      padding-left: 20px;
      padding-right: 20px;
      @include breakpoint(medium only) {
        margin-left: 50px;
        margin-right: 50px;
        padding: 0;
      }
      @include breakpoint(large only) {
        margin-left: 100px;
        margin-right: 100px;
        padding: 0;
      }
      @include breakpoint(xlarge) {
        margin-left: 150px;
        margin-right: 150px;
        padding: 0;
      }
      p {
        margin: 0;
        color: $color-secondary;
        font-size: 1rem;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;

        //line-height: 1.25rem;
        a {
          color: $color-primary;
          font-weight: bold;
          text-decoration: underline;
        }
      }
      @include breakpoint (small only) {
        margin: 0 0 1.5rem 0;
        &.last {
          margin: 0;
        }
      }
    }
  }

  .footer__info__icon1 {
    margin-left: 30px;
    @include breakpoint(medium) {
      margin-left: 60px;
    }
  }

  .footer__info__icon2 {
    margin-left: 30px;
  }

  .footer__info__header {
    padding-top: 30px;
    padding-bottom: 10px;
    h5 {
      letter-spacing: 2px;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      color: $color-secondary;
    }
  }

  .footer__info__text {
    p {
      padding-bottom: 15px;
      color: $color-secondary !important;
    }
  }


  .footer__middle {
    background-color: #f1f1f1;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 10px;
    position: relative;
    float: left;
    width: 100%;
    @include breakpoint(medium) {
      padding-left: 0;
    }
    .epsilon-header {
      font-weight: $global-weight-normal;
      color: $color-primary;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 1px;
      margin-bottom: 12px;
      margin-top: -3px;
      @include breakpoint(medium) {
        margin-top: 28px;
      }
    }
    ul {
      margin: 0;
      list-style: none;
      li {
        font-size: 0.875rem;
        a {
          color: $color-secondary;
          font-family: 'Roboto', sans-serif;
          letter-spacing: 2px;
          font-size: 15px;
          @include breakpoint(medium only) {
            font-size: 12px;
          }
          &:hover {
            font-weight: 600;
          }
        }
      }
    }
    .footer__contact__info {
      p {
        margin: 0;
        color: $color-secondary;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 2px;
        font-size: 15px;
        font-weight: 600;
        @include breakpoint(medium only) {
          font-size: 12px;
        }
      }
    }
  }

  .footer__logo {
    margin-bottom: 16px;
    width: 30%;
    @include breakpoint(medium) {
      width: 80%;
      margin-top: 34px;
    }
    @include breakpoint(large) {
      width: 72%;
    }
    @include breakpoint(xlarge) {
      width: 55%;
    }
  }

  .inspired-logo {
    margin-top: 50px;
    @include breakpoint(medium) {
      margin-top: 20px;
    }
}


  .footer__middle-lower {
    background-color: #f1f1f1;
    @include breakpoint(large) {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }

  .mail-form {
    label {
      color: #167cba;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 1px;
      font-size: 18px;
      font-weight: 400;
      padding-top: 3px;
    }
    .btn, input[type="submit"] {
      background-color: #02113d;
      color: white;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 2px;
      margin-top: -21px;
      font-size: 15px;
      margin-left: -1px;
      &:hover {
        background-color: #147bb9;
      }
    }
    input[type="email"] {
      border: 1px solid #167cba;
    }
  }


  .footer__bottom {
    background-color: $color-secondary;
    padding-top: 25px;
    padding-bottom: 21px;
    font-size: 0.750rem;
    * {
      color: $white;
    }
    p.company__terms {
      line-height: 1.3rem;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 1px;
      padding-top: 20px;
    }
    .company__site-by {
      text-align: right;
      font-family: 'Roboto', sans-serif;
      padding-top: 31px;
      p {
        margin-bottom: 5px;
        letter-spacing: 1px;
      }
      img {
        max-width: 100px;
      }
      a {
        font-weight: bold;
      }
    }
  }
  .about__services-list {
    font-size: 0.875rem;
    .fa {
      padding-top: 5px;
      font-size: 1rem;
    }
  }
}

.sedex-logo {
  margin-top: 20px;
}


.product__category.search__product {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  @include breakpoint(large only) {
    width: 95%;
  }
  @include breakpoint(xlarge) {
    width: 90%;
  }
}

.product__category__content .product__category .products__search .products__availability td:first-child {
  width: 50%;
}

// manual master category page
.master-catgories {
  position: relative;
  float: left;
  margin-top: 55px;
  .master-category__image {
    margin-bottom: 30px;
    box-shadow: 0 0 5px 0 $medium-gray;
    img {
      width: 100% !important;
    }
  }
}




// Fixes FOUC On mobile YAY!!!
@include breakpoint(small only) {
  .top-bar {
    display: none;
  }
}

@include breakpoint(large) {
  .title-bar {
    display: none;
  }
}


// Fixes FOUC on desktop YAY!!!
[data-responsive-toggle]{
    display:none;
}

// Fixes FOUC on desktop YAY!!!
[data-dropdown-menu] ul {
  display: none;
}

// imagelightbox
#imagelightbox
{
  position: fixed;
  z-index: 9999;

  -ms-touch-action: none;
  touch-action: none;

  -webkit-box-shadow: 0 0 3.125em rgba( 255, 255, 255, .75 ); /* 50 */
  box-shadow: 0 0 3.125em rgba( 255, 255, 255, .75 ); /* 50 */
}

#imagelightbox-close {
  position: fixed;
  width:10px;
  height: 10px;
  z-index: 9999;
  color: #02113d;
  margin-left: -40px;
  margin-top: 11px;
  font-size: 30px;
}

#imagelightbox-overlay
{
  background-color: #000;
  background-color: rgba( 0, 0, 0, .7 );
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* ACTIVITY INDICATION */

#imagelightbox-loading,
#imagelightbox-loading div
{
  border-radius: 50%;
}
#imagelightbox-loading
{
  width: 2.5em; /* 40 */
  height: 2.5em; /* 40 */
  background-color: #444;
  background-color: rgba( 0, 0, 0, .5 );
  position: fixed;
  z-index: 10003;
  top: 50%;
  left: 50%;
  padding: 0.625em; /* 10 */
  margin: -1.25em 0 0 -1.25em; /* 20 */

  -webkit-box-shadow: 0 0 2.5em rgba( 0, 0, 0, .75 ); /* 40 */
  box-shadow: 0 0 2.5em rgba( 0, 0, 0, .75 ); /* 40 */
}
#imagelightbox-loading div
{
  width: 1.25em; /* 20 */
  height: 1.25em; /* 20 */
  background-color: #fff;

  -webkit-animation: imagelightbox-loading .5s ease infinite;
  animation: imagelightbox-loading .5s ease infinite;
}

@-webkit-keyframes imagelightbox-loading
{
  from { opacity: .5;	-webkit-transform: scale( .75 ); }
  50%	 { opacity: 1;	-webkit-transform: scale( 1 ); }
  to	 { opacity: .5;	-webkit-transform: scale( .75 ); }
}
@keyframes imagelightbox-loading
{
  from { opacity: .5;	transform: scale( .75 ); }
  50%	 { opacity: 1;	transform: scale( 1 ); }
  to	 { opacity: .5;	transform: scale( .75 ); }
}



// TEMPLATE PAGE 1 //

[data-page-tag="template-1"] {
  margin-top: 20px;
  @include breakpoint(medium only) {
    margin-top: 55px;
  }
  @include breakpoint(large only) {
    margin-top: 16px;
  }
  @include breakpoint(xlarge) {
    margin-top: 16px;
  }
  .row {
    @extend .collapse;
  }
  .content-section.three__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      position: relative;
      margin-bottom: 60px;
      @include breakpoint(medium) {
        margin-bottom: 30px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: all 0.5s ease;
        background-color: transparent;
      }
      &:hover {
        &::before {
          background-color: #f1f0ef;
          opacity: 0.5;
        }
      }
    }
  }

  .content-section.four__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      position: relative;
      margin-bottom: 60px;
      @include breakpoint(medium) {
        margin-bottom: 30px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: all 0.5s ease;
        background-color: transparent;
      }
      &:hover {
        &::before {
          background-color: #f1f0ef;
          opacity: 0.5;
        }
      }
    }
  }


  .content-section.two__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      margin-bottom: 15px;
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }


  h4 {
    font-size: 25px;
    margin-left: 20px;
    margin-bottom: -60px;
    color: $color-secondary;
    @include breakpoint(medium only) {
      font-size: 18px;
      margin-left: 40px;
      margin-bottom: -45px;
    }
    @include breakpoint(large only) {
      margin-bottom: -45px;
      margin-left: 40px;
      font-size: 1.5625rem;
    }
    @include breakpoint(xlarge) {
      margin-bottom: -69px;
      margin-left: 60px;
      font-size: 1.5625rem;;
    }
  }

  .template-header {
    text-align: center;
  }

  h3 {
    font-size: 32px;
    margin-bottom: 40px;
    @include breakpoint(medium only) {
      margin-top: -30px;
      margin-bottom: 50px;
      font-size: 40px;
    }
    @include breakpoint(large only) {
      margin-top: 0;
      margin-bottom: 50px;
      font-size: 40px;
      line-height: 1;
    }
    @include breakpoint(xlarge) {
      margin-bottom: 50px;
      font-size: 40px;
      line-height: 1;
    }
  }

  .template-section-header {
    text-align: center;
    position: relative;
    border-top: solid 3px $color-secondary;
    padding-top: 10px;
    border-bottom: solid 1px $color-secondary;
    margin-bottom: 20px;
    h4 {
      letter-spacing: 2px;
      color: $color-secondary;
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
}


// TEMPLATE PAGE 2 //

[data-page-tag="template-2"] {
  margin-top: 20px;
  @include breakpoint(medium only) {
    margin-top: 55px;
  }
  @include breakpoint(large only) {
    margin-top: 16px;
  }
  @include breakpoint(xlarge) {
    margin-top: 16px;
  }

  .template-header {
    text-align: center;
  }

  h3 {
    font-size: 32px;
    margin-bottom: 40px;
    @include breakpoint(medium only) {
      margin-top: -30px;
      margin-bottom: 50px;
      font-size: 40px;
    }
    @include breakpoint(large only) {
      margin-top: 0;
      margin-bottom: 50px;
      font-size: 40px;
      line-height: 1;
    }
    @include breakpoint(xlarge) {
      margin-bottom: 50px;
      font-size: 40px;
      line-height: 1;
    }
  }

  .block__image__title__4 {
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: -45px;
    left: 0;
    text-align: center;
    h4 {
      color: $color-secondary;
      margin: 0;
      font-size: 18px;
      letter-spacing: 2px;
    }
  }

  .content-section.three__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      position: relative;
      margin-bottom: 60px;
      @include breakpoint(medium) {
        margin-bottom: 30px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: all 0.5s ease;
        background-color: transparent;
      }
      &:hover {
        &::before {
          background-color: #f1f0ef;
          opacity: 0.5;
        }
      }
    }
  }

  .content-section.four__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      position: relative;
      margin-bottom: 60px;
      @include breakpoint(medium) {
        margin-bottom: 30px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: all 0.5s ease;
        background-color: transparent;
      }
      &:hover {
        &::before {
          background-color: #f1f0ef;
          opacity: 0.5;
        }
      }
    }
  }

  .template-section-header {
    text-align: center;
    position: relative;
    border-top: solid 3px $color-secondary;
    padding-top: 10px;
    border-bottom: solid 1px $color-secondary;
    margin-bottom: 20px;
    h4 {
      letter-spacing: 2px;
      color: $color-secondary;
      font-size: 28px;
    }
  }

  .content-section.six__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      transition: all 0.2s ease;
      margin-bottom: 32px;
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
      &:hover {
        box-shadow: 0px 0px 18px 4px #dcd9d7;
        transform: scale(1.1);
      }
    }
  }

  .content-section.two__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      margin-bottom: 15px;
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }
}



// TEMPLATE PAGE 3 //

[data-page-tag="template-3"] {
  margin-top: 20px;

  @include breakpoint(medium only) {
    margin-top: 55px;
  }
  @include breakpoint(large only) {
    margin-top: 16px;
  }
  @include breakpoint(xlarge) {
    margin-top: 16px;
  }

  .content-section.four__block {
    .block__image {
      position: relative;
      margin-bottom: 60px;
      @include breakpoint(medium) {
        margin-bottom: 30px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: all 0.5s ease;
        background-color: transparent;
      }
      &:hover {
        &::before {
          background-color: #f1f0ef;
          opacity: 0.5;
        }
      }
    }
  }

  .template-header {
    text-align: center;
  }

  h3 {
    font-size: 32px;
    margin-bottom: 40px;
    @include breakpoint(medium only) {
      margin-top: -30px;
      margin-bottom: 50px;
      font-size: 40px;
    }
    @include breakpoint(large only) {
      margin-top: 0;
      margin-bottom: 50px;
      font-size: 40px;
      line-height: 1;
    }
    @include breakpoint(xlarge) {
      margin-bottom: 50px;
      font-size: 40px;
      line-height: 1;
    }
  }


  .content-section.three__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      position: relative;
      margin-bottom: 60px;
      @include breakpoint(medium) {
        margin-bottom: 30px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: all 0.5s ease;
        background-color: transparent;
      }
      &:hover {
        &::before {
          background-color: #f1f0ef;
          opacity: 0.5;
        }
      }
    }
  }

  .content-section.six__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      transition: all 0.2s ease;
      margin-bottom: 32px;
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
      &:hover {
        box-shadow: 0px 0px 18px 4px #d7e7f1;
        transform: scale(1.1);
      }
    }
  }

  .content-section.two__block {
    position: relative;
    float: left;
    width: 100%;
    .block__image {
      margin-bottom: 15px;
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }

  .template-section-header {
    text-align: center;
    position: relative;
    border-top: solid 3px $color-secondary;
    padding-top: 10px;
    border-bottom: solid 1px $color-secondary;
    margin-bottom: 20px;
    h4 {
      letter-spacing: 2px;
      color: $color-secondary;
      font-size: 28px;
    }
  }
}

// DISCONTINUED ALERT

.discontinued-alert {
  text-align: center;
  float: left;
  width: 100%;
  margin-top: 27px;
  @include breakpoint(medium) {
    margin-top: 62px;
  }
}

// Search //

.header__top--inactive {
  .header__search {
    margin-top: 30px;
  }
}