
// box model
.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
  &.full-width {
    left: 0;
    right: 0;
    max-width: none;
  }
}

.pos-fix {
  position: fixed;
  &.full-width {
    left: 0;
    right: 0;
    max-width: none;
  }
}

.ds-bl {
  display: block;
}

.ds-ib {
  display: inline-block;
}

.ds-il {
  display: inline;
}

// leave this at 50, if your using these helpers for styles that need px changes > 50 then you should be writing
// a class for it, lazy...
$max-px: 50;

@for $i from 1 to $max-px {
  .mt-#{$i} {
    margin-top: (1px * $i);
  }
}

@for $i from 1 to $max-px {
  .ml-#{$i} {
    margin-left: (1px * $i);
  }
}

@for $i from 1 to $max-px {
  .mr-#{$i} {
    margin-right: (1px * $i);
  }
}

@for $i from 1 to $max-px {
  .mb-#{$i} {
    margin-bottom: (1px * $i);
  }
}

@for $i from 1 to $max-px {
  .pt-#{$i} {
    padding-top: (1px * $i);
  }
}

@for $i from 1 to $max-px {
  .pl-#{$i} {
    padding-left: (1px * $i);
  }
}

@for $i from 1 to $max-px {
  .pr-#{$i} {
    padding-right: (1px * $i);
  }
}

@for $i from 1 to $max-px {
  .pb-#{$i} {
    padding-bottom: (1px * $i);
  }
}

.mg-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

// Typography
.text-uppercase {
  text-transform: uppercase;
}

