body {
	line-height:1.75rem;
}
p {
	line-height: 1.75rem;
}
a[href^="tel"]{
    color: $color-secondary;
    text-decoration:none;
}

.hidden {
  display: none;
}

a {
  color: $color-secondary;
  text-decoration:none;
  font-weight: normal;
  &:hover {
    color: $color-primary;
  }
}
