/*------------------------------------*\
    #HELPER STYLES
\*------------------------------------*/
.main-content {
  position: relative;
  float: left;
  width: 100%;
  //transition: padding-top .5s;
  //padding-top: 30px;
}

.background {
  &.background--black-rgba {
     background-color: rgba($black, 0.4);
   }
  &.background--dark-gray {
     background-color: $dark-gray;
  }
  &.background--white {
    background-color: $white;
  }
  &.background--box-shadow {
    box-shadow: 0 0 5px 0 #E2E2E2;
  }
  &.background--light-gray {
    background-color: #f1f1f1;
  }
}

.breadcrumbs {
  background:transparent;
  //border-bottom: 1px solid $primary-color;
  margin: 0;
  padding: 0.25rem 0.75rem;
  li {
    text-transform: none;
    color: $secondary-color;
    font-size: 0.85rem;
    font-weight: $global-weight-normal;
    &:not(:last-child){
      &::after {
        color: $dark-gray;
        content: "\203A";
      }
    }
  }
  a {
    color: $primary-color;
  }
}

.breadcrumbs-wrapper {
  background: #f1f1f1;
}

input[type="text"], input[type="password"], input[type="email"] {
  height: 2.5125rem;
}

.pagination {
  font-weight: $global-weight-bold;
  li {
    margin: 0;
    &.ellipsis {
      display: none;
      &:after {
        color: $primary-color;
      }
    }
    &.inactive {
      display: none;
    }
  }
  .current {
    background: $color-hover;
    color: $color-secondary;
  }
  a {
    color: $color-secondary;
    &:hover {
      background: $color-hover;
    }
  }
}

.button {
  font-weight: $global-weight-bold;
  &.button--padded {
    padding: 0.85em 3em;
  }
  &.button--form {
    text-align: left;
    padding: 0.85em 5em 0.85em 1.5em;
  }
}

.button.primary {
  background-color: $color-tertiary !important;
}

.button.secondary {
  background-color: $color-primary;
  &:hover {
    background-color: $color-primary;
  }
}

.button.primary.basket-button {
  background-color: $color-tertiary !important;
  color: white;
  border: solid 1px $color-tertiary;
}

.button.primary.product__increase {
  background-color: $color-primary !important;
  color: white;
  border: solid 1px $color-primary;
}

.button.primary.product__decrease {
  background-color: $color-primary !important;
  color: white;
  border: solid 1px $color-primary;
}

.btn,
  input[type="submit"] {
  -webkit-appearance: none;
  border:0px;
  background: $color-tertiary;
  color: $white;
  font-weight: $global-weight-bold;
  padding: 0.85em 1em;
  font-size: 1rem;
}

.global-form-error {
  color: $error-color;
  font-weight: $global-weight-bold;
}

/*------------------------------------*\
    #SECTION BLOCKS - 
    USED FOR PAGE LAYOUTS
\*------------------------------------*/

.page__wrapper {
  background: $white;
  padding: 0 2rem 2rem 2rem;
  margin: 0 0 2rem 0;
  float:left;
  width:100%;
  h3, h4 {
    margin: 0 0 1rem 0;
    font-weight: $global-weight-normal;
    color: $black;
  }
  h4 {
    color: #7a7a7a;
  }
}

.block {

  background: #fff;
  //box-shadow: 0 0 3px rgba(184,184,184,0.35);

  .block__image {
    position: relative;
    .block__image__title {
      padding: 1rem;
      width: 100%;
      background: rgb(36, 36, 36); /* The Fallback */
      background: rgba(36, 36, 36, 0.75); 
      position: absolute;
      bottom: 0;
      left: 0;
      h4 {
        color: $white;
        margin: 0;
      }
    }
  }


  .block__image__title__1 {
    padding: 1rem;
    width: 100%;
    background: rgb(2, 17, 61); /* The Fallback */
    background: rgba(2, 17, 61, 0.9);
    position: absolute;
    bottom: 0;
    left: 0;
    h4 {
      color: $white;
      margin: 0;
      font-size: 23px;
      letter-spacing: 1px;
    }
  }


  .block__image__title__2 {
    padding: 1rem;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 0;
    background: rgba(245,247,251,0.6);
    h4 {
      color: $color-secondary;
      margin: 0;
      font-size: 23px;
      letter-spacing: 1px;
    }
  }

  .block__image__title__3 {
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: -45px;
    left: 0;
    text-align: center;
    h4 {
      color: $color-secondary;
      margin: 0;
      font-size: 18px;
      letter-spacing: 2px;
    }
  }

  .block__image__title__4 {
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: -45px;
    left: 0;
    text-align: center;
    h4 {
      color: $color-secondary;
      margin: 0;
      font-size: 18px;
      letter-spacing: 2px;
    }
  }

  .block__image__title__6 {
    padding: 10px;
    width: 100%;
    text-align: center;
    h4 {
      color: $color-secondary;
      margin: 0;
      font-size: 15px;
      letter-spacing: 1px;
    }
  }


  .block__copy {
    p {
      font-weight: 100;
      font-size: 0.9rem;
      color: $color-secondary;
    }
    h3,
    h4 {
      color: $color-secondary;
      border-bottom:1px solid $color-secondary;
      padding: 0 0 0.875rem 0;
      margin: 0 0 0.875rem 0;
    }
  }

}

.generic-block-module {
  padding: 1rem;
  width: 100%;
  background: #f8f8f8;
  margin-bottom: 2rem;
  float: left;
  //.generic-block-module__content {
    h5 {
      color: $color-secondary;
      border-bottom: 1px solid $medium-gray;
      padding: 0 0 0.75rem 0;
      font-weight: $global-weight-bold;
      margin-bottom: 1.2rem;
      span {
        display: inline-block;
        margin: 0 0 0 0.625rem;
      }
      .fa {
        font-size: 0.875rem;
      }
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5rem;
      margin: 1rem 0;
    }
  //}
  ul {
    list-style-type: none;
    margin: 0.9375rem 0 0 0;
    li {
      a {
        display: block;
        color: $dark-gray;
        border-bottom: 0px !important;
        padding: 0 0 0.75rem 0.75rem;
        font-weight: $global-weight-normal;
      }
    }
  }
}

/*------------------------------------*\
    #ACCOUNT
\*------------------------------------*/

.account,
.cart { 
  float: left;
  width:100%;
  padding: 1.5rem 0rem;
  //margin: 1.875rem 0;
  margin: 0;

  .accordion {
    margin-top: 3.4375rem;
    border: 0px;
    box-shadow: 0 0 3px rgba(184,184,184,0.35);
    @include breakpoint(small only) {
      margin-top:0;
      margin-bottom: 1.5rem;
    }
    .accordion-item {
      a {
        font-size: 1rem;
        color: $primary-color;
        border-bottom: 1px solid $medium-gray;
      }
    }

    .accordion-content {
      ul {
        list-style-type: none;
        margin: 0;
        li {
          a {
            display: block;
            border-bottom: 0px;
            font-size: 0.875rem;
            span {
              display: inline-block;
              margin: 0 0 0 0.625rem;
            }
            &:hover {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }

  .optBtn {
    .txtLink {
      background: $color-tertiary;
      color: $white;
      font-weight: $global-weight-bold;
      padding: 0.5rem 0.9375rem;
    }
  }

  .account__title {
    h3 {
      font-weight: $global-weight-normal;
      color: $color-secondary;
      border-bottom: 1px solid $medium-gray;
      padding: 0 0 0.75rem 0;
      margin: 0 0 1.5rem 0;
    }
    p {
      margin: 0;
    }
    &.account__title__gold {
      p {
        margin: 0 0 1.5rem 0;
      }
    }
  }

  .account__orders,
  .account__returns {
    margin: 1.5rem 0;
    table {
      font-size: 0.875rem;
      thead {
        th {
          font-weight: $global-weight-normal;
          color: $black;
        }
      }
    }
  }

  .account__returns__info {
    margin: 0 0 1.5rem 0;
    a {
      strong {
        color: $black;
        font-weight: $global-weight-normal;
      }
    }
    dl {
      dt {
        font-weight: $global-weight-bold;
      }
    }
  }

  .account__profile {
    margin: 1.5rem 0;
    h4 {
      border-bottom: 1px solid $medium-gray;
      font-weight: $global-weight-normal;
      margin: 1.5rem 0;
      padding: 0 0 1rem 0;
    }
    form {
      label {
        margin: 0 0 0.3125rem 0;
      }
      input {
        border: 1px solid $medium-gray;
        &:focus {
          border: 1px solid $black;
        }
      }
      textarea {
        border: 1px solid $medium-gray;
        &:focus {
          border: 1px solid $black;
        }
      }
      input[type="submit"] {
        color: $white;
        border:0px;
        font-weight: $global-weight-normal;
        font-size: 1rem;
      }
      select {
        border: 1px solid $medium-gray;
        &:focus {
          border: 1px solid $black;
        }
      }
    }
  }

  .account__comms {
    input[type="submit"] {
      color: $white;
      border:0px;
      font-weight: $global-weight-normal;
      font-size: 1rem;
      margin-top:1rem;
    }
  }

  .account__logout {
    text-align:center;
  }

  .account__login__error {
    padding: 0 0 1rem 0;
    p {
      margin: 0;
    }
  }

  .account__login,
  .account__login__register {
    //padding: 1rem 0 1.75rem 0;
    float: left;
    width:100%;
    h4 {
      font-weight: $global-weight-normal;
      border-bottom: 1px solid $medium-gray;
      padding: 0 0 0.75rem 0;
      margin: 0 0 0.75rem 0;
      color: $color-primary;
    }
    form {
      label {
        margin: 0 0 0.3125rem 0;
      }
      input {
        border: 1px solid $medium-gray;
        &:focus {
          border: 1px solid $black;
        }
      }
      input[type="submit"],
      a {
        color: $white;
        border:0px;
        font-weight: $global-weight-bold;
        font-size: 1rem;
      }
    }
    a {
      color: $white;
      border:0px;
      font-weight: $global-weight-bold;
      font-size: 1rem;
    }
    a.forgotten__password {
      color: $color-secondary;
      font-size: 13px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .account__register {

    &.account__register--padding {
      .columns {
        padding: 0;
      }
    }

    .account__title--centered {
      text-align: center;
    }

    h4 {
      font-weight: $global-weight-normal;
      border-bottom: 1px solid $medium-gray;
      padding: 0 0 0.75rem 0;
      margin: 0 0 1rem 0;
    }
    
    label {
      margin: 0 0 0.3125rem 0;
    }
    textarea,
    input,
    select {
      border: 1px solid $medium-gray;
      border-radius: 0px;
      &:focus {
        border: 1px solid $black;
      }
    }
  }

  .callout {
    text-align: center;
    p {
      color: $black;
    }
  }
}

.account__order__view {
  h5 {
    color: $medium-gray;
  }
}

.account__order__view__options {
  margin: 0 0 1.5rem 0;
  a {
    display: inline-block;
    margin-right: 1rem;
    .fa {
      margin: 0 10px 0 0;
    }
  }
}

.account__order__view__address,
.account__order__view__shipping,
.account__order__view__invoice,
.account__order__view__overview {
  margin: 0 0 1.875rem 0;
}


/*------------------------------------*\
    #HELP PAGES
\*------------------------------------*/

.help__page { 
  float: left;
  width:100%;
  padding: 1.5rem 1rem;
  margin: 1.875rem 0;

  .help__page__wrapper {
    padding: 15px 15px 30px 15px;
  }

  //h3,
  //h2,
  //h4,
  //h5 {
  //    font-weight: $global-weight-normal;
  //    border-bottom: 1px solid $medium-gray;
  //    padding: 0 0 0.75rem 0;
  //    margin: 0 0 1.5rem 0;
  //  }
  //
  //h5 {
  //  margin: 0 0 1rem 0;
  //}

  ul {
    margin-bottom: 2.25rem;
  }

  #help__page__general__enquiry {
    margin: 2.5rem 0 0 0;
  }

  form {
    label {
      margin: 0 0 0.3125rem 0;
    }
    input {
      border: 1px solid $medium-gray;
      &:focus {
        border: 1px solid $black;
      }
    }
    textarea {
      border: 1px solid $medium-gray;
      &:focus {
        border: 1px solid $black;
      }
    }
    input[type="submit"],
      a {
        color: $white;
        border:0px;
        font-weight: $global-weight-normal;
        font-size: 1rem;
    }
  }

  .help__page__delivery__panel {
    margin: 0 0 1.875rem 0;
    &.help__page__delivery__panel-Xmargin {
      margin: 0 0 2.5rem 0;
    }
  }

  .accordion__wrapper {
    float: left;
    @include breakpoint (medium down) {
      margin-bottom: 1.875rem;
    }
  }

  .accordion {
    border: 0px;
    .accordion-item {
      .accordion-title {
        color: $color-secondary;
        font-size: 1rem;
        border-bottom: 1px solid $color-secondary;
        &:hover {
          background: $color-hover;
        }
      }
      .accordion-content {
        ul {
          margin: 0 0 0 1.25rem;
          list-style-type: square;
          li {
            padding: 0 0 5px 0;
            a {
              color: $color-secondary;
              &:hover {
                color: $color-tertiary;
              }
            }
          }
        }
      }
    }
  }
}

label {
  color: $color-secondary !important;
}


/*------------------------------------*\
    #FIX FOR ORBIT
\*------------------------------------*/


.orbit-container {
  height: inherit !important;
  width: 100%;
  .orbit-slide {
    max-height: inherit !important;
  }
}


/*------------------------------------*\
    #CART
\*------------------------------------*/

.visual-basket {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  bottom: 0;
  width: inherit;
  background: $light-gray;
  z-index: -2;
  &.visual-basket--on {
    background: #bad2b9;
    z-index: -1;
    a {
      font-size: inherit;
      color: inherit;
      display: inline-block;
    }
  }
}

.visual-basket-control {
  position: absolute;
  width: 90px;
  height: 30px;
  top: 0;
  right: 0;
  text-align: left;
  padding: 0 10px 0 8px;
  background: #8ead8c;
  .visual-basket-control__qty {
    color: $black;
  }
  .visual-basket-control__remove {
    background: red;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 28px;
    text-align: center;
    a {
      font-size: inherit !important;
      display: inline-block !important;
      color: $white !important;
      margin: 7px !important;
      i {
        position: absolute;
        top: 8px;
        left: 0;
        right: 0;
      }
    }
  }
}

.cart__progress {
  margin: 2.5rem 0; 
  float: left;
  width:100%;
  ul {
    float: left;
    width:100%;
    margin: 0;
    list-style-type: none;
    li {
      text-align: center;
      color: $white;
      font-size: 0.875rem;
      font-weight: $global-weight-bold;
      margin: 0 3px;
      min-width: 24%;
      height: 36px;
      line-height: 36px;
      float: left;
      position: relative;
      background-color: $color-secondary;
      -webkit-transition: background-color 0.2s ease;
      -moz-transition: background-color 0.2s ease;
      -ms-transition: background-color 0.2s ease;
      -o-transition: background-color 0.2s ease;
      transition: background-color 0.2s ease;
      &.active {
            background-color: $color-tertiary;
            color: $white;
        &:after {
          border-left: 17px solid $color-tertiary;
          z-index: 1;
        }
        span {
          display: inline-block;
          margin-left: 0.9375rem;
        }
      }
      &:after,
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        right: -16px;
        width: 0;
        height: 0;
        border-top: 19px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 17px solid $color-secondary;
        z-index: 1;
        -webkit-transition: border-color 0.2s ease;
        -moz-transition: border-color 0.2s ease;
        -ms-transition: border-color 0.2s ease;
        -o-transition: border-color 0.2s ease;
        transition: border-color 0.2s ease;
      }
      &:before {
        right: auto;
        left: 0;
        border-left: 17px solid $white;
        z-index: 0;
      }
      &:first-child {
        &:before {
          border: none;
        }
        @include breakpoint (small only) {
          span {
            margin: 0;
          }
        }
      }
      @include breakpoint (small only) {
        min-width:22%;
        span {
          display: inline-block;
          margin-left: 20px;
        }
      }
    }
  }
}

.cart__price__summary {
  float: left;
  width: 100%;
  margin: 1rem 0 2.5rem 0; 
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    float: left;
    width:100%;
    text-align: center;
    border:1px solid $medium-gray;
    li {
      float: left;
      display: inline-block;
      padding: 1rem;
      width:16.666666667%;
      font-weight: $global-weight-bold;
      font-size: 1.25rem;
      @include breakpoint (small only) {
        width:50%;
        border-bottom: 1px solid $medium-gray;
        &.last {
          border-bottom: 0px;
        }
      }
      &.cart__price__summary__title {
        background: $medium-gray;
        color: $color-primary;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 2px;
        @include breakpoint (small only) {
          border-bottom: 1px solid $white;
          &.last {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

.cart__minimum__order {
  margin: 0 0 2.5rem 0;
}

/*------------------------------------*\
    #Error
\*------------------------------------*/

.errorContainer {
  float: left;
  width:100%;
  margin: 0 0 1.875rem 0;
  margin-top: 30px;
  .notfound {
    float: left;
    width:100%;
    padding: 1.5rem;
    text-align: center;
    p {
      margin: 0;
    }
  }
  #goog-fixurl {
    float: left;
    width:100%;
    margin: 1.5rem 0 0 0;
    form {
      margin: 0.625rem 0 0 0;
    }
    h3 {
      b {
        font-weight: $global-weight-normal;
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
    }
    input[type="submit"] {
      float: right;
    }
  }
}