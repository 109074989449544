.menu {
  .is-active {
    background: #f2f2f2;
  }
  .is-dropdown-submenu-parent {
    position: static;
    @include breakpoint(small only) {
      position: relative;
    }
  }
  .is-dropdown-submenu {
    border: 0 none;
    @include breakpoint(small only) {
      * {
        color: $white !important;
        font-size: 0.875rem !important;
      }
    }
  }
}